'use client'
import React from 'react'
import {
  canHoverMq,
  isPortraitMq,
  isTouchScreenMq,
  notebookDownMq,
  notebookUpMq,
  phoneDownMq,
  phoneUpMq,
  tabletDownMq,
  tabletToNotebookMq,
  tabletUpMq,
} from '../styles/mediaQueries'
import { useMatchMedia } from './useMatchMedia'
import { DeviceMediaQueries } from '../common/style/Display'

type TContext = Required<DeviceMediaQueries> | undefined

const Context = React.createContext<TContext>(undefined)

type Props = React.PropsWithChildren<{ userAgentIsMobile: boolean | null }>

/**
 * Device Detect will return whether the user's screen is small, medium, large, or mobile (small and medium both included).
 * On the server, this is done using the user agent from the request. once on the browser, it is based on the actual
 * screen size and adjusts if the screen size changes
 * !! Note:
 *    on the first render from the server, isTabletDown and isTabletToNotebook may both be true since they will both default to
 *    detecting a mobile user agent. After a single render the actual screen size will be calculated and only one will
 *    be true at a time though
 */
export function DeviceProvider({ children, userAgentIsMobile }: Props) {
  // set the default state to be that of the userAgent mobile/tablet detection for first render on server. once on
  // browser, this will use the actual media query, so the default won't matter and it will just reflect the actual
  // size throughout the app's lifecycle
  const isPhoneDown = useMatchMedia(phoneDownMq, userAgentIsMobile === true)
  const isPhoneUp = useMatchMedia(phoneUpMq, userAgentIsMobile === true)
  const isTabletDown = useMatchMedia(tabletDownMq, userAgentIsMobile === true)
  const isTabletUp = useMatchMedia(tabletUpMq, !userAgentIsMobile)
  const isTabletToNotebook = useMatchMedia(tabletToNotebookMq, userAgentIsMobile === true)
  const isNotebookDown = useMatchMedia(notebookDownMq, userAgentIsMobile === true)
  const isNotebookUp = useMatchMedia(notebookUpMq, !userAgentIsMobile)
  const canHover = useMatchMedia(canHoverMq, !userAgentIsMobile)
  const isPortrait = useMatchMedia(isPortraitMq, true)
  const isTouchScreen = useMatchMedia(isTouchScreenMq, userAgentIsMobile === true)

  const value = React.useMemo(
    () => ({
      isTabletDown,
      isPhoneUp,
      isTabletToNotebook,
      isTabletUp,
      isNotebookUp,
      isNotebookDown,
      canHover,
      isPortrait,
      isPhoneDown,
      isTouchScreen,
    }),
    [
      isPhoneUp,
      isTabletDown,
      isTabletUp,
      isTabletToNotebook,
      isNotebookDown,
      isNotebookUp,
      canHover,
      isPortrait,
      isPhoneDown,
      isTouchScreen,
    ]
  )

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useDevice = () => {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error('useDevice must be used within a DeviceProvider')
  }
  return context
}
