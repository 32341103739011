import React from 'react'
import { getCssProp } from '../../utilities/getCssProp'
import { theme } from '../../styles/theme'
import { useGetShortestRotationFromOrientation } from '../hooks/useGetShortestRotationFromOrientation'
import { RotatableSvgComponentProps, SvgBase } from '../common'

export function NavigationArrow({
  className,
  orientation = 'down',
  color = theme.colors.grayscale900,
  styles,
  size = 20,
  ...rest
}: RotatableSvgComponentProps) {
  const rotation = useGetShortestRotationFromOrientation(orientation)

  const cssProp = getCssProp(styles)
  return (
    <SvgBase
      css={cssProp}
      $rotation={rotation}
      $size={size}
      viewBox="0 0 20 20"
      fill="none"
      className={className}
      {...rest}
    >
      <g id="Navigation/Arrow">
        <path
          id="Rectangle 12"
          d="M14.25 8L10 12.25L5.75 8"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgBase>
  )
}
